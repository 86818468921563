import React from "react";
import { useState } from "react";
import axios from "axios";
import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, MenuItem, Paper, Select, Switch, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import Hist from "./Hist";

const url = document.location.protocol + "//" + document.location.host;
//const url = "http://localhost";

function Control({ gid, lcgHeight }) {

    const [das, setDas] = useState(() => {
        return false
    });
    const [pv, setPv] = useState(() => {
        return false
    });
    const [id, setId] = useState(() => {
        return 0
    });
    const [isRun, setRun] = useState(() => {
        return false
    });


    const handleChangeSw = (event) => {

        statusCh(event.target.checked);
    };

    if (gid != id) {
        setId(gid);
        setDas(false);
    }

    const sendOpt = () => {
        let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
        axios.post(url + "/fix/control.php", {
            key: s_key,
            c: 2,
            robot: gid,
            pv: pv,
            t: das.data,
        }).then(res => {
            // console.log(res.data);
            if (res.data != false) {
            } else {
                alert("Ошибка связи с сервером1");
            }
        }).catch(() => {
            alert("Ошибка связи с сервером2");
        })
    }

    const closeAll = () => {
        let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
        axios.post(url + "/fix/control.php", {
            key: s_key,
            c: 4,
            act: 2,
            robot: gid,
        }).then(res => {
            if (res.data != false) {
            } else {
                alert("Ошибка связи с сервером1");
            }
        }).catch(() => {
            alert("Ошибка связи с сервером2");
        })
    }

    const statusCh = (isRunl) => {
        let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
        axios.post(url + "/fix/control.php", {
            key: s_key,
            c: 3,
            status: isRunl ? 1 : 0,
            robot: gid,
        }).then(res => {
            setRun(res.data == "1" ? true : false);

        }).catch(() => {
            alert("Ошибка связи с сервером2");
        })
    }

    const changeInputProp = event => {
        //event.persist()
        //console.log("" + event.target.name + ":" + event.target.value);
        setPv(prev => {
            return { ...prev, [event.target.name]: event.target.value, }
        })
    }

    const changeInputChek = event => {
        let re = event.target.checked ? 1 : 0;
        setPv(prev => {
            return { ...prev, [event.target.name]: re, }
        })
    }

    var mi = [];

    if (!das) {
        let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
        axios.post(url + "/fix/control.php", {
            key: s_key,
            robot: gid,
            c: 1,
        }).then(res => {
            // console.log(gid);
            //console.log(res.data);
            if (res.data != false) {
                setPv(res.data.pv);
                setDas({ data: res.data.t, dop: res.data.dop, pv: res.data.pv, gr: res.data.tg });
                setRun(res.data.run == "1" ? true : false);
                setId(res.data.id);
            } else {
                alert("Ошибка связи с сервером1");
            }
        }).catch(() => {
            alert("Ошибка связи с сервером2");
        })
    } else {
        for (let index1 = 0; index1 < das.gr.length; index1++) {
            const el1 = das.gr[index1];
            var mi1 = [];
            for (let index = 0; index < das.data.length; index++) {
                const el = das.data[index];
                if (el.idgroup != el1.id) continue;

                let inel = <input type="text" name={el.pkey} value={pv[el.pkey] || ""} onChange={changeInputProp} />;
                if (el.ptype == "I")
                    inel = <input type="number" step="1" name={el.pkey} value={pv[el.pkey] || ""} onChange={changeInputProp} />;
                if (el.ptype == "D")
                    inel = <input type="number" step="0.01" name={el.pkey} value={pv[el.pkey] || ""} onChange={changeInputProp} />;
                if (el.ptype == "B")
                    inel = <Checkbox
                        checked={pv[el.pkey] || ""}
                        onChange={changeInputChek}
                        name={el.pkey}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                if (el.ptype.length > 1) {
                    var options = [];
                    for (let index1 = 0; index1 < das.dop[el.ptype].length; index1++) {
                        const els = das.dop[el.ptype][index1];
                        options.push(<MenuItem key={els.value} value={els.value}>{els.name}</MenuItem>);
                    };
                    inel = <Select style={{ width: "50%" }}
                        value={pv[el.pkey] || ""}
                        name={el.pkey}
                        onChange={changeInputProp}>
                        {options}

                    </Select>


                }

                mi1.push(<div key={el.pkey} style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>

                    <label style={{ textAlign: "end", width: "100%" }}>
                        {el.description ? el.description : el.pkey}   :</label>
                    {inel}

                </div>);
            }

            mi.push(<Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,

                },
            }}><Paper elevation={3} sx={{ p: 2, }}>
                    <Typography variant="h6" component="h2">
                        {el1.name}
                    </Typography>{mi1}</Paper></Box>);
        }
    }
    if (mi.length > 0) {
        return <Container key={'h1'} id={'h1'} style={{ maxHeight: lcgHeight, overflow: "auto", maxWidth: "100%" }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                },
            }}>
                <Paper elevation={3} sx={{ p: 2, }}>
                    <ButtonGroup variant="contained" aria-label="outlined button group">
                        <Button variant="contained" size="large" onClick={sendOpt} color="success">Save</Button>
                        <Button variant="contained" size="large" onClick={closeAll} color="error">Close All</Button>
                    </ButtonGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Switch checked={isRun} onChange={handleChangeSw} />}
                            label="is Run"
                        />
                    </FormGroup>



                </Paper>
                <Hist gid={gid} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                },
            }}> {mi}
            </Box>

        </Container>;
    } else return <div>Loading...</div>

}


export default Control;
