
import React from 'react';
import Chart from './Chart';
import axios from 'axios';


const url = document.location.protocol + "//" + document.location.host;
//const url = "http://localhost";

class ChartComponent extends React.Component {
	tick() {
		let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
		axios.post(url + "/fix/api.php", {
			key: s_key,
			robot: this.props.gid,
		}).then(res => {
			
			if (res.data !== false) {
				let data = res.data;
				var mi = [];
				var pd = [];

				data.md.forEach(el => {
					mi.push({
						date: new Date(parseInt(el.date)),
						open: parseFloat(el.open),
						high: parseFloat(el.high),
						low: parseFloat(el.low),
						close: parseFloat(el.close),
						ask: parseFloat(el.ask),
						volume: parseFloat(el.volume)

					})
				});
				data.pd.forEach(el => {
					pd.push({
						side: parseInt(el.side),
						openPrice: parseFloat(el.openPrice),
						closePrice: parseFloat(el.closePrice),
						closeTime: parseInt(el.closeTime),
						openTime: parseInt(el.openTime),
						tooltip: el.tooltip
					})
				});
				if (mi.length > 0)
					this.setState({ data: mi, posd: pd, sltp: data.sltp, prop: data.opt });
				//console.log(mi);
			} else {
				//alert("Ошибка добавления, неправильно введенные данные");
			}
		}).catch(() => {
			//alert("An error occurred on the server");
		})

	}
	componentDidMount() {
		this.timerID = setInterval(
			() => this.tick(),
			1000
		);

		/* 		getData().then(data => {
			this.setState({ data })
		})
 */this.tick()
	}

	render() {
		if (this.state == null) {
			return <div>Loading...</div>
		}
		//console.log(this.state.data);

		return (
			<Chart data={this.state.data} posd={this.state.posd} sltp={this.state.sltp} prop={this.state.prop} gHeight={this.props.gHeight} />
		)
	}
}


export default ChartComponent;
