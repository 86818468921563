
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import * as React from 'react';

const columns = [
    { id: 'time', label: 'Time', minWidth: 170 },
    { id: 'symbol', label: 'Symbol', minWidth: 50 },
    {
        id: 'syde',
        label: 'Side',
        minWidth: 30,
        align: 'right',
    }, {
        id: 'size',
        label: 'Size',
        minWidth: 70,
        align: 'right',
        format: (value) => value.toFixed(1),
    }, {
        id: 'priceopen',
        label: 'Price open',
        minWidth: 70,
        align: 'right',
        format: (value) => value.toFixed(5),
    }, {
        id: 'priceclose',
        label: 'Price close',
        minWidth: 70,
        align: 'right',
        format: (value) => value.toFixed(5),
    }, {
        id: 'profit',
        label: 'Profit',
        minWidth: 70,
        align: 'right',
        format: (value) => value.toFixed(2),
    },

];

export default function Histе(rowss) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
  //  const { rows } = this.props;
    const rows=rowss.rows;
    //console.log(rows);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // setInterval(tick, 500);

    if (!rows) {
        return <div>empty</div>
    } else
        return (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 330 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.time}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        );
}



