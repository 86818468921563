import React, { Component } from "react";
import PropTypes from "prop-types";
import { GenericChartComponent } from "react-stockcharts"

class DrawPositions extends Component {
  constructor(props) {
    super(props);
    this.renderSVG = this.renderSVG.bind(this);
  }
  render() {
    return <GenericChartComponent
      svgDraw={this.renderSVG}
      drawOn={["pan"]}
    />;
  }

  renderSVG(moreProps) {
    const { xAccessor } = moreProps;
    const { xScale, chartConfig: { yScale }, plotData, fullData } = moreProps;

    const { className, usingProps, with: Annotation } = this.props;
    const { positions } = this.props


    if (!positions || positions.length === 0 || !yScale) {
      return null
    }

    return (<g className={`react-stockcharts-enable-interaction ${className}`}>{

      positions.map((position, index) => {
        const { side, openPrice, closePrice, closeTime, openTime, tooltip } = position

        const openPositionY = yScale(openPrice)
        const openPositionX = xScale(helper(new Date(openTime), plotData))//xScale(new Date(openTime))
        if (closePrice == 0) {
          const PositionX2 = xScale(new Date(plotData[0].date))
          const PositionX1 = xScale(helper(new Date(plotData[0].date), plotData))

          return (
            <g key={index}>
              <title>{tooltip}</title>
              <line
                x1={PositionX1}
                y1={openPositionY}
                x2={PositionX2}
                y2={openPositionY}
                stroke={'#32cd32'}
                strokeWidth={0.6}
                strokeDasharray={[5, 3]}
              />
              <path d={openPath(openPositionX, openPositionY)} stroke={'#FFF'}
                strokeWidth={0.3}
                fillOpacity={1}
                fill={side == 2 ? '#FF0000' : '#06a949'} />

            </g>
          )
        } else {
          const closePositionX = xScale(helper(new Date(closeTime), fullData))
          const closePositionY = yScale(closePrice)
          return (
            <g key={index}>
              <title>{tooltip}</title>
              <line
                x1={openPositionX}
                y1={openPositionY}
                x2={closePositionX}
                y2={closePositionY}
                stroke={'#FF0000'}
                strokeWidth={0.3}
                strokeDasharray={[5, 5]}
              />
              <path d={openPath(openPositionX, openPositionY)} stroke={'#FFF'}
                strokeWidth={0.3}
                fillOpacity={1}
                fill={side == 2 ? '#FF0000' : '#06a949'}

              />

              <path d={closePath(closePositionX, closePositionY)} stroke={'#FFF'}
                strokeWidth={0.3}
                fillOpacity={1}
                fill={'#daa520'} />
            </g>
          )
        }

      })

    }</g>)


  }
}

function openPath(x, y) {
  const halfHeigh = 3;
  const width = 6;
  const height = 6;
  return `M${x} ${y} `
    + `L${x - width} ${y - halfHeigh} `
    + `L${x - width} ${y + halfHeigh} `
    + `L${x} ${y} `
    + "Z";
}
function closePath(x, y) {
  const halfHeigh = 3
  const width = 6;
  const height = 6;
  return `M${x} ${y} `
    + `L${x + width} ${y - halfHeigh} `
    + `L${x + width} ${y + halfHeigh} `
    + `L${x} ${y} `
    + "Z";
}

function helper(ltime, plotData) {

  let r = plotData.filter(d => (d.date.getTime() == ltime.getTime()));

  return r.length > 0 ? r[0].idx.index : false;
}

export default DrawPositions;
