
import { MenuItem, MenuList, Typography } from '@mui/material';
import axios from 'axios';
import * as React from 'react';

const url = document.location.protocol + "//" + document.location.host;
//const url = "http://localhost";

class RBotList extends React.Component {
    constructor(props) {
        super(props);
        this.handleClicM = this.handleClicM.bind(this);
    }

    componentDidMount() {
        let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
        axios.post(url + "/fix/control.php", {//document.location.protocol + "//" + document.location.host + "
            key: s_key,
            robot: this.props.gid,
            c: 6,
        }).then(res => {
            //console.log(res.data);
            if (res.data != false) {
                this.setState({ data: res.data });
            } else {
                alert("Ошибка связи с сервером1");
            }
        }).catch(() => {
            alert("Ошибка связи с сервером2");
        })
    }

    handleClicM(event) {
        event.persist()
       // console.log(event);
        if (event.target.id) {
            let dt = this.state.data;
            for (let index = 0; index < dt.length; index++) {
                dt[index].sel = false;
                if (dt[index].id == event.target.id) {
                    dt[index].sel = true;
                    this.props.setgId(dt[index].id);
                }
            }
            this.setState({ data: dt });
        }
    }

    render() {

        if (this.state == null) {
            return <div>Loading...</div>
        }
        var mi = [];
        for (let index = 0; index < this.state.data.length; index++) {
            const el = this.state.data[index];
            mi.push(
                <MenuItem key={el.id} id={el.id} selected={el.sel} onClick={this.handleClicM}>
                    <Typography id={el.id} variant="inherit">{el.name}</Typography>
                </MenuItem>
            );
        }
        var mis = [];
        mis.push(<Typography key={"w1"} variant="inherit">Account list</Typography>);
        mis.push(
            <MenuList key={"w2"}>
                {mi}
            </MenuList>);
        return (mis)

    }
}


export default RBotList;
