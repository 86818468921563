import React, { useEffect, useRef } from "react";
import ChartComponent from "./ChartComponent";
import Control from "./Control";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Hist from "./Hist";
import RBotList from "./RBotList";
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Fab } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const [register, setRegister] = useState(() => {
        return {
            password: "",
        }
    })
    const [logon, setLogon] = useState(() => { return false })
    const [gId, setgId] = useState(() => { return 0 })
    const [gHeight, setgHeight] = useState(() => { return 500 })
    const inputRef = useRef();
    const [showControl, setshowControl] = useState(() => { return false })


    const changeInputRegister = event => {
        event.persist()
        setRegister(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })
    }

    useEffect(() => {
        if (logon)
            setgHeight(inputRef.current.offsetHeight);
    });

    const submitChackin = event => {
        event.preventDefault();
        if (register.password == "147256") {
            localStorage.setItem("logon", true);
            setLogon(true);
        }
    }

    if (!logon && localStorage.getItem("logon"))
        setLogon(true);


    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };
    /*         
            
     */
    if (logon) {
        var lgHeight = "100vh"
        var lcgHeight = "0px"
        if (showControl) {
            lgHeight = "60vh";
            lcgHeight = "40vh";
        }
        return <div >
            <div style={{
                display: "flex",
                flexDirection: "row-reverse",
                height: lgHeight,
                overflow: "none"
            }} ref={inputRef}>
                <div style={{ width: "100%" }}>
                    <ChartComponent gid={gId} gHeight={gHeight} />
                </div>
                <div style={{ width: "150px" }}>
                    <RBotList setgId={setgId} />
                </div>
            </div>
            <Control gid={gId} lcgHeight={lcgHeight} />
            <Fab sx={fabStyle} aria-label={"Setings"} color={'primary'} onClick={() => setshowControl(!showControl)}>
                <UpIcon />
            </Fab>
        </div >;
    } else {
        return <div className="form">
            <h2>Login:</h2>
            <form onSubmit={submitChackin}>
                <p>Password: <input
                    type="password"
                    id="password"
                    name="password"
                    value={register.password}
                    onChange={changeInputRegister}
                /></p>
                <input type="submit" />
            </form>
        </div>
    }



}


export default App;
