

import { BottomNavigation, BottomNavigationAction, Chip, Container, Paper, Typography } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import Histе from './Histe';

const url = document.location.protocol + "//" + document.location.host;
//const url = "http://localhost";

class Hist extends React.Component {
    tick() {
        let s_key = "i1KyeVtHfIPZuzw7ql4movt204";
        axios.post(url + "/fix/control.php", {//document.location.protocol + "//" + document.location.host + "
            key: s_key,
            robot: this.props.gid,
            c: 5,
            vh: this.state.vtype,
        }).then(res => {
            //console.log(res);
            if (res.data != false) {
                this.setState({ data: res.data.data,prof: res.data.prof });
  
            } else {
                alert("Ошибка связи с сервером1");
            }
        }).catch(() => {
            alert("Ошибка связи с сервером2");
        })

    }
    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
        this.setState({ vtype: 0 , prof: 0 });
        /* 		getData().then(data => {
			this.setState({ data })
		})
 */	}

    render() {
        if (this.state == null) {
            return <div>Loading...</div>
        }
        //console.log(this.state.data);
        let ch = null;
        if (this.state.prof < 0) {
            ch = <Chip label={ "P/L "+ this.state.prof.toFixed(2)} color="error" />;
        }else{
            ch = <Chip label={ "P/L "+ this.state.prof.toFixed(2)} color="success" />;
        }
        return (
            <Paper>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <BottomNavigation
                        showLabels
                        value={this.state.vtype}
                        onChange={(event, newValue) => {
                            this.setState({ vtype: newValue });
                            this.tick()
                        }}
                    >
                        <BottomNavigationAction label="Active" />
                        <BottomNavigationAction label="History" />

                    </BottomNavigation>
                  {ch}
                </div>
                <Histе rows={this.state.data} />
            </Paper>)
    }
}


export default Hist;
