
import React from "react";
import PropTypes from "prop-types";

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { scaleTime } from "d3-scale";

import { ChartCanvas, Chart } from "react-stockcharts";
import {
	CandlestickSeries,
	LineSeries,
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
	CrossHairCursor,
	EdgeIndicator,
	CurrentCoordinate,
	MouseCoordinateX,
	MouseCoordinateY,
	PriceCoordinate,

} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import {
	OHLCTooltip,
	MovingAverageTooltip,
} from "react-stockcharts/lib/tooltip";
import { ema, sma, wma } from "react-stockcharts/lib/indicator";
import { fitWidth } from "react-stockcharts/lib/helper";
import algo from "react-stockcharts/lib/algorithm";
import {
	Label,
	Annotate,
	SvgPathAnnotation,
	buyPath,
	sellPath,
} from "react-stockcharts/lib/annotation";
import { last } from "react-stockcharts/lib/utils";
import DrawPositions from "./DrawPositions"

class RoboFIX extends React.Component {


	render() {
		const { type, data: initialData, width, ratio, posd, sltp, prop,gHeight } = this.props;
		var ma50;
		var ma20;
		var nma20;
		var nma50;
		if (prop.FirstMAMethod == 1) {
			ma20 = ema()
				.id(1)
				.options({ windowSize: parseInt(prop.FirstMA) })
				.merge((d, c) => { d.ma20 = c; })
				.accessor(d => d.ma20);
			nma20 = "EMA";
		}
		else {
			ma20 = sma()
				.id(1)
				.options({ windowSize: parseInt(prop.FirstMA) })
				.merge((d, c) => { d.ma20 = c; })
				.accessor(d => d.ma20);
			nma20 = "SMA";
		}
		if (prop.SecondMAMethod == 1) {
			ma50 = ema().
				id(2)
				.options({ windowSize: parseInt(prop.SecondMA) })
				.merge((d, c) => { d.ma50 = c; })
				.accessor(d => d.ma50);
			nma50 = "EMA";
		}
		else {
			ma50 = sma()
				.id(2)
				.options({ windowSize: parseInt(prop.SecondMA) })
				.merge((d, c) => { d.ma50 = c; })
				.accessor(d => d.ma50);
			nma50 = "SMA";
		}
		const margin = { left: 10, right: 80, top: 30, bottom: 50 };
		const height = gHeight;

		const calculatedData = ma50(ma20(initialData));
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor(d => d.date);
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(calculatedData);

		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - 150)]);
		const xExtents = [start, end];


		var ma = [];
		ma.push(<LineSeries yAccessor={ma20.accessor()} stroke={ma20.stroke()} />);
		ma.push(<LineSeries yAccessor={ma50.accessor()} stroke={ma50.stroke()} />);
		ma.push(<CurrentCoordinate yAccessor={ma20.accessor()} fill={ma20.stroke()} />);
		ma.push(<CurrentCoordinate yAccessor={ma50.accessor()} fill={ma50.stroke()} />);
		ma.push(<MovingAverageTooltip
			onClick={e => console.log(e)}
			origin={[0, 15]}
			displayFormat={format(".5f")}
			options={[
				{
					yAccessor: ma20.accessor(),
					type: nma20,
					stroke: ma20.stroke(),
					windowSize: ma20.options().windowSize,
				},
				{
					yAccessor: ma50.accessor(),
					type: nma50,
					stroke: ma50.stroke(),
					windowSize: ma50.options().windowSize,
				},
			]}
		/>);
		//}


		/* 		const xAccessor = d => d.date;
			
			
				const start = xAccessor(last(data));
				const end = xAccessor(data[Math.max(0, data.length - 70)]);
				const xExtents = [start, end];
		 */
		var wsltp = [];

		if (parseFloat(sltp.tp) != 0)
			wsltp.push(<PriceCoordinate
				at="left"
				orient="right"
				price={parseFloat(sltp.tp)}
				stroke="#5ce795"
				strokeWidth={1}
				fill="#FFFFFF"
				textFill="#5ce795"
				arrowWidth={7}
				strokeDasharray="ShortDash"
				key={"pc1"}
				displayFormat={format(".5f")}
			/>);
		if (parseFloat(sltp.sl) != 0)
			wsltp.push(<PriceCoordinate
				at="left"
				orient="right"
				price={parseFloat(sltp.sl)}
				stroke="#d03333"
				strokeWidth={1}
				fill="#FFFFFF"
				textFill="#d03333"
				arrowWidth={7}
				strokeDasharray="ShortDash"
				displayFormat={format(".5f")}
				key={"pc2"}
			/>);


		return (
			<ChartCanvas height={height}
				width={width}
				ratio={ratio}
				margin={margin}
				type={type}
				seriesName="MSFT"
				data={data}
				xScale={xScale}
				xAccessor={xAccessor}
				displayXAccessor={displayXAccessor}
				xExtents={xExtents}
			>
				<Chart id={1}
					yExtents={[d => [d.high, d.low], ma20.accessor(), ma50.accessor()]}
					padding={{ top: 10, bottom: 20 }}>
					<XAxis axisAt="bottom" orient="bottom" />
					<YAxis axisAt="right" orient="right" ticks={5} />
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d %H:%M:%S")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".5f")} />

					<CandlestickSeries width={4} />


					<EdgeIndicator yAxisPad={-5} itemType="last" orient="left" edgeAt="right" lineStrokeDasharray="Solid" lineStroke="#FF0000"
						yAccessor={d => d.ask} fill={"#FF0000"} displayFormat={format(".5f")} />{/* d => d.close > d.open ? "#6BA583" : "#FF0000" */}
					<EdgeIndicator itemType="last" orient="right" edgeAt="right" lineStrokeDasharray="Solid"
						yAccessor={d => d.close} fill={"#6BA583"} displayFormat={format(".5f")} />

					<OHLCTooltip origin={[0, 0]} displayFormat={format(".5f")} />
					{ma}
					{wsltp}
					<DrawPositions positions={posd} />

				</Chart>
				<CrossHairCursor />
			</ChartCanvas>
		);
	}
}

/*
					<LineSeries yAccessor={d => d.close} stroke="#000000" />

*/

RoboFIX.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

RoboFIX.defaultProps = {
	type: "svg",
};

RoboFIX = fitWidth(RoboFIX);

export default RoboFIX;
